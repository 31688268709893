body {
  margin: 0;
  font-family: 'Sequel', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'Sequel Sans-SemiBold Body';
  font-style: normal;
  font-weight: 600;
  src: url('assets/fonts/Sequel-Sans-Semi-Bold-Body-Text.otf')
    format('opentype');
}
@font-face {
  font-family: 'Sequel Sans-SemiBold Head';
  font-style: normal;
  font-weight: 600;
  src: url('assets/fonts/Sequel Sans Semi Bold Head.ttf') format('truetype');
}
@font-face {
  font-family: 'Sequel Sans-RomanBody';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/OGJ Type Design - Sequel Sans Roman Body.otf')
    format('opentype');
}
@font-face {
  font-family: 'Sequel Sans-BoldHead';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/Sequel Sans Bold Head.ttf') format('truetype');
}
@font-face {
  font-family: 'Sequel Sans-BookBody';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/Sequel-Sans-Book-Body-Text.otf') format('opentype');
}
@font-face {
  font-family: 'Sequel Sans-BoldBody';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/Sequel-Sans-Bold-Body-Text.otf') format('opentype');
}
@font-face {
  font-family: 'Sequel Sans-RomanHead';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/Sequel Sans Roman Head.ttf') format('truetype');
}
@font-face {
  font-family: 'Sequel Sans-MediumHead';
  font-style: normal;
  font-weight: 500;
  src: url('assets/fonts/Sequel Sans Medium Head.ttf') format('truetype');
}
@font-face {
  font-family: 'Sequel Sans-SemiBold';
  font-style: normal;
  font-weight: 600;
  src: url('assets/fonts/Sequel Sans Semi Bold Disp.ttf') format('truetype');
}
@font-face {
  font-family: 'SF Pro-Semibold';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/SF-Pro-Text-Semibold.otf') format('opentype');
}
@font-face {
  font-family: 'SF Pro-Bold';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/SF-Pro.ttf') format('truetype');
}
@font-face {
  font-family: 'Sequel Sans-BoldDisp';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/SequelSans-BoldDisp.ttf') format('truetype');
}
@font-face {
  font-family: 'SF Pro-Display-Semibold';
  font-style: normal;
  font-weight: 600;
  src: url('assets/fonts/SF-Pro-Display-Semibold.ttf') format('opentype');
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ffff;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ffff;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Sequel Sans-MediumHead';
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0 0 3rem 0;
  font-family: 'Ovo', serif;
  font-size: 1rem;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

hr {
  border: none;
}
.MuiInput-root.MuiInput-underline::after {
  border-bottom: none;
}
.MuiInput-root.MuiInput-underline::before {
  border-bottom: none;
}